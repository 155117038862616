import { IonContent, IonAvatar, IonIcon, IonButton, IonModal } from "@ionic/react";
import { peopleOutline } from "ionicons/icons";
import { useEffect } from "react";
import { useHistory } from "react-router";
import { useAppSelector } from '../../app/hooks';
import { selectEventName } from '../../app/slices/initDataSlice';
import { translate } from "../../app/translate";

interface Props {
    setCheckedMatchmaking: (bool: boolean) => void;
    isOpen: boolean;
    onClose: () => void;
}

const MatchmakingReminder: React.FC<Props> = (props: Props) => {

    const history = useHistory();
    const event_name: string | null | undefined = useAppSelector(selectEventName);
    const { isOpen, onClose } = props;

    useEffect(() => {
        isOpen && props.setCheckedMatchmaking(true);
    }, [isOpen, props]);

    return (
        <IonModal
            isOpen={isOpen}
            onDidDismiss={() => onClose()}
        >
            <IonContent>
                <div className='ion-justify-content-center ion-align-items-center' style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    boxSizing: 'border-box',
                    padding: '16px',
                    textAlign: 'center'
                }} >
                    <IonAvatar
                        className='ion-justify-content-center ion-align-items-center'
                        style={{
                            width: '76px',
                            height: '76px',
                            display: 'flex',
                            backgroundColor: 'var(--ion-color-primary)'
                        }}
                    >
                        <IonIcon size="large" color='light' icon={peopleOutline} />
                    </IonAvatar>
                    <h2>{translate('Welcome to the :eventName app', { eventName: event_name ? event_name : 'ChatGo' })}</h2>
                    <p>{translate("Don't forget to complete your profile so we can connect you with other attendees")}</p>
                    <div className='ion-padding'>
                        <IonButton
                            color='primary'
                            onClick={() => {
                                history.push('/matchmakingsettings')
                                onClose()
                            }}
                        >{translate("Let's do it")}</IonButton>
                        <IonButton
                            color='medium'
                            fill='outline'
                            onClick={() => {
                                history.push('/home')
                                onClose()
                            }}
                        >{translate('Maybe later')}</IonButton>
                    </div>
                </div>
            </IonContent>
        </IonModal>
    )
}

export default MatchmakingReminder;