import { IonBadge, IonButton, IonContent, IonHeader, IonText, IonItem, IonLabel, IonList, IonModal, IonSegment, IonSegmentButton, IonTitle, IonToolbar } from "@ionic/react";
import { Fragment, useEffect, useState } from "react";
import Moment from 'react-moment';
import { useHistory } from "react-router";
import { useAppSelector } from '../app/hooks';
import { selectGroups, selectGroupsLastMessages } from '../app/slices/groupSlice';
import { selectAllRooms, selectLastMessages } from '../app/slices/roomSlice';
import { selectUserTimestamps, selectUserId } from '../app/slices/userSlice';
import { VscBell, VscBellDot } from "react-icons/vsc";
import { selectEventToggles } from '../app/slices/eventSlice';
import { EventToggles } from '../app/types/eventToggles';
import { updateRoomsNotifications, updateGroupNotifications } from "./Classes/NotificationControllers";
import { translate } from "../app/translate";



const NotificationBell: React.FC = ({ children }) => {
    const history = useHistory();
    const userId = useAppSelector(selectUserId);
    const rooms = useAppSelector(selectAllRooms);
    const groups = useAppSelector(selectGroups);
    const lastRoomMessages = useAppSelector(selectLastMessages);
    const lastGroupMessages = useAppSelector(selectGroupsLastMessages);
    const userTimestamps = useAppSelector(selectUserTimestamps);
    const [roomUnreadCount, updateRoomUnreadCount] = useState(0);
    const [groupUnreadCount, updateGroupUnreadCount] = useState(0);
    const [notificationPopoverOpen, setnotificationPopoverOpen] = useState(false);
    const [notificationPage, setnotificationPage] = useState('chatrooms');
    const eventToggles: EventToggles | never[] = useAppSelector(selectEventToggles);

    useEffect(() => {
        const count = updateRoomsNotifications(rooms, lastRoomMessages, userTimestamps);
        updateRoomUnreadCount(count);
    }, [rooms, lastRoomMessages, userTimestamps]);

    useEffect(() => {
        eventToggles && !eventToggles.chat_rooms && setnotificationPage('inbox');
    }, [eventToggles])

    useEffect(() => {
        const count = updateGroupNotifications(groups, lastGroupMessages, userTimestamps, userId);
        updateGroupUnreadCount(count);
    }, [groups, lastGroupMessages, userTimestamps, userId]);

    return (
        <Fragment>
            <IonButton
                slot='end'
                fill={'clear'}
                className='ion-margin-end'
                // color='light'
                onClick={() => setnotificationPopoverOpen(true)}
                style={{ color: `var(--ion-color-primary-contrast)` }}
            >
                {
                    roomUnreadCount + groupUnreadCount > 0 ?
                        <VscBellDot size={21} /> : <VscBell size={21} />
                }
                {
                    roomUnreadCount + groupUnreadCount > 0 &&
                    <IonBadge style={{ marginLeft: '2px' }} color='light' >
                        <IonText color='primary' >{roomUnreadCount + groupUnreadCount}</IonText>
                    </IonBadge>
                }
            </IonButton>
            <IonModal
                isOpen={notificationPopoverOpen}
                onDidDismiss={() => setnotificationPopoverOpen(false)}
            >
                <IonHeader>
                    <IonToolbar color='primary'>
                        <IonTitle>
                            {translate(`What's New`)}
                        </IonTitle>
                        <IonButton
                            color='light'
                            fill='clear'
                            slot='end'
                            onClick={() => setnotificationPopoverOpen(false)}
                        >
                            <IonLabel>{translate('Close')}</IonLabel>
                        </IonButton>
                    </IonToolbar>
                    <IonToolbar>
                        <IonSegment
                            value={notificationPage}
                            onIonChange={(e) => setnotificationPage(String(e.detail.value))}
                        >
                            {eventToggles.chat_rooms &&
                                <IonSegmentButton value={'chatrooms'}>
                                    <IonLabel>{translate('Chatrooms (:roomUnreadCount)', { roomUnreadCount: roomUnreadCount.toString() })}</IonLabel>
                                </IonSegmentButton>
                            }

                            {eventToggles.direct_messaging &&
                                <IonSegmentButton value={'inbox'}>
                                    <IonLabel>{translate('Messages (:groupUnreadCount)', { groupUnreadCount: groupUnreadCount.toString() })}</IonLabel>
                                </IonSegmentButton>
                            }
                        </IonSegment>
                    </IonToolbar>
                </IonHeader>
                <IonContent>

                    {
                        notificationPage === 'chatrooms' ?
                            <IonList>
                                {
                                    roomUnreadCount > 0 ?
                                        Object.entries(lastRoomMessages).map(([key, value], index) => (
                                            (userTimestamps[key] && value.time > parseInt(userTimestamps[key])) &&
                                            <IonItem
                                                key={`notification_room_${key}`}
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    setnotificationPopoverOpen(false);
                                                    setTimeout(() => {
                                                        history.push(`/chatroom/${key}`);
                                                    }, 300);
                                                }}
                                            >
                                                <IonLabel>
                                                    <h2>{translate('New Message in :name', { room: rooms.find(room => room.id === parseInt(key))?.name ?? '' })}</h2>
                                                    <p><Moment fromNow>{value.time * 1000}</Moment></p>
                                                </IonLabel>
                                            </IonItem>
                                        ))
                                        :
                                        <div
                                            className='ion-padding ion-text-center'
                                        >{translate('No new conversations in the chat rooms')}</div>
                                }
                            </IonList>
                            :
                            <IonList>
                                {
                                    groupUnreadCount > 0 ?
                                        Object.entries(lastGroupMessages).map(([key, value], index) => (
                                            (userTimestamps[key] && value.time > parseInt(userTimestamps[key])) && groups.find(group => group.id === key) &&
                                            <IonItem
                                                key={`notification_group_${key}`}
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    setnotificationPopoverOpen(false);
                                                    setTimeout(() => {
                                                        history.push(`/group/${key}`);
                                                    }, 300);
                                                }}
                                            >
                                                <IonLabel>
                                                    <h2>{translate('New Message from :firstName', {firstName: value.sender.firstName})}</h2>
                                                    <p><Moment fromNow>{value.time * 1000}</Moment></p>
                                                </IonLabel>
                                            </IonItem>
                                        ))
                                        :
                                        <div
                                            className='ion-padding ion-text-center'
                                        >{translate('No new messages from attendees')}</div>
                                }
                            </IonList>
                    }

                </IonContent>
            </IonModal>
        </Fragment>
    )
}

export default NotificationBell;