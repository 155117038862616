import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { store } from './app/store';
import { Provider } from 'react-redux';
import { InitData } from './app/types/InitData';
import Color from 'color';
import CheckLocal from './components/Classes/CheckLocal';
import { CheckEnv } from './components/Classes/CheckEnv';
import { getBrandings } from './components/Classes/Brandings';
import CheckWidget from './components/Classes/CheckWidget';

//check if isStandalone or isWidget
const isWidget = CheckWidget();
const rootElement = document.getElementById('root');

// get search query from url to get init autoAuth params
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const initIsAutoAuth = urlParams.get('isAutoAuth');
const initEventKey = urlParams.get('eventKey');
const initMode = urlParams.get('mode');
const localAccess = CheckLocal();
const appType = CheckEnv(); 


// check if it is widget first then update init event key
if (isWidget && !initIsAutoAuth && initEventKey) {
  localAccess && localStorage.setItem('event_key', String(initEventKey));
}

let initData: InitData = {
  eventKey: isWidget ? initEventKey : '',
  // userToken: '',
  // firebaseToken: '',
  dmId: null,
  roomId: null,
  isAutoAuth: !isWidget ? false : !initIsAutoAuth ? false : parseInt(initIsAutoAuth) === 0 ? false : true,
  dmBack: false,
  event_name: null,
  themeColors: null,
  mode: isWidget ? initMode : null
};


isWidget && window.addEventListener('message', event => {
  // IMPORTANT: check the origin of the data! 
  if (
    // event.origin.startsWith('https://staging.streamgo.dev') ||
    // event.origin.startsWith('http://streamgo.test') ||
    // event.origin.startsWith('http://localhost') ||
    true
  ) {
    let temp: any = { ...initData };
    for (const [key, value] of Object.entries(event.data)) {
      if (event.data.hasOwnProperty(key)) {
        temp[key] = value;
      }
    }
    initData = temp;
    
    // handle theme colors
    if (initData.themeColors) {
      addThemeColorsCssVars(initData.themeColors);
    }
      
    rootRender();
  }
});

const addThemeColorsCssVars = (colors: { colour_1: string, colour_2: string }) => {
  // REMARKS: the package 'Color' has issue with babel if you use >= 4.0.1 version, hence we sticks with v3.2.1
  const color1 = Color(colors.colour_1);
  const color2 = Color(colors.colour_2);


  var themeColorsCss = `
    :root{
      /** primary **/
      --ion-color-primary: ${color1.hex()};
      --ion-color-primary-rgb: ${color1.rgb()};
      --ion-color-primary-contrast: ${color1.isDark() ? '#FFFFFF' : '#222222'};
      --ion-color-primary-contrast-rgb: ${color1.isDark() ? '255,255,255' : '22,22,22'};
      --ion-color-primary-shade: ${color1.darken(0.3).hex()};
      --ion-color-primary-tint: ${color1.lighten(0.3).hex()};

      /** secondary **/
      --ion-color-secondary: ${color2.hex()};
      --ion-color-secondary-rgb: ${color2.rgb()};
      --ion-color-secondary-contrast: ${color2.isDark() ? '#FFFFFF' : '#222222'};
      --ion-color-secondary-contrast-rgb: ${color2.isDark() ? '255,255,255' : '22,22,22'};
      --ion-color-secondary-shade: ${color2.darken(0.3).hex()};
      --ion-color-secondary-tint: ${color2.lighten(0.3).hex()};
    }
  `;
  
  var themeColorsTag = document.createElement('style');
  themeColorsTag.appendChild(document.createTextNode(themeColorsCss));
  themeColorsTag.id = 'event-theme-vars';
  document.body.append(themeColorsTag);
}

async function rootRender() {
  if (!isWidget) {
    await setupBrandings();
  };
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <App
          initData={initData}
        />
      </Provider>
    </React.StrictMode>,
    rootElement
  );
}

const setupBrandings = async () => {
  if (appType === 'event') {
    var brandingApiOptions = {
      method: 'GET',
      headers: {
        'accept': 'application/json',
        'authorization': `Bearer ${process.env.REACT_APP_EVENT_TOKEN}`
      }
    }
    const brandRes = await fetch(`${process.env.REACT_APP_CHATGO_API}/branding`, brandingApiOptions);
    const brandingData = await brandRes.json();
    var branding = brandingData.meta.custom.widget;
    let temp = { ...initData };
    temp['event_name'] = brandingData.name;
    initData = temp;
    addThemeColorsCssVars({
      colour_1: branding.colour_1,
      colour_2: branding.colour_2
    })
  } else if (appType === 'org') {
    const eventKey = store.getState().auth.event_key;
    if (eventKey) {
      getBrandings(eventKey);
    } else {
      var brandingApiOptions = {
        method: 'GET',
        headers: {
          'accept': 'application/json',
          'authorization': `Bearer ${process.env.REACT_APP_ORGANISATION_KEY}`
        }
      }
      const brandRes = await fetch(`${process.env.REACT_APP_CHATGO_API}/organisation/branding`, brandingApiOptions);
      const brandingData = await brandRes.json();
      var branding = brandingData.meta.custom.widget;
      let temp = { ...initData };
      temp['event_name'] = brandingData.name;
      initData = temp;
      addThemeColorsCssVars({
        colour_1: branding.colour_1,
        colour_2: branding.colour_2
      }) 
    }
  } else {
    const eventKey = store.getState().auth.event_key;
    if (eventKey) {
      getBrandings(eventKey);
    } else {
      let temp = { ...initData };
      temp['event_name'] = 'ChatGo';
      initData = temp;
      addThemeColorsCssVars({
        colour_1: '#44bdb5',
        colour_2: '#f6653c'
      }) 
    }
  }
}

rootRender();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();