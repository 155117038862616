import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../store";
import { InitData } from "../types/InitData";
import CheckLocal from "../../components/Classes/CheckLocal";
import CheckWidget from "../../components/Classes/CheckWidget";


const isWidget = CheckWidget();
// get search query from url to get init autoAuth params
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const initEventKey = urlParams.get('eventKey');
const initIsAutoAuth = urlParams.get('isAutoAuth') && parseInt(String(urlParams.get('isAutoAuth'))) === 1 ? true : false;

const initialState: InitData = {
    eventKey: isWidget ? String(initEventKey) : String(process.env.REACT_APP_EVENT_TOKEN) || '',
    userToken: initIsAutoAuth || !CheckLocal() ? '' : localStorage.getItem('token') || '',
    firebaseToken: initIsAutoAuth || !CheckLocal() ? '' : localStorage.getItem('firebase_token') || '',
    dmId: null,
    roomId: null,
    dmBack: false,
    isAutoAuth: initIsAutoAuth,
    event_name: null,
    themeColors: null,
    mode: null
}

export const initDataSlice = createSlice({
    name: 'initData',
    initialState,
    reducers: {
        setDmId: (state: InitData, action: PayloadAction<string>) => {
            state.dmId = action.payload
        },
        setRoomId: (state: InitData, action: PayloadAction<string | number>) => {
            state.roomId = action.payload
        },
        setDmBack: (state: InitData, action: PayloadAction<boolean>) => {
            state.dmBack = action.payload
        },
        setIsAutoAuth: (state: InitData, action: PayloadAction<boolean>) => {
            state.isAutoAuth = action.payload
        },
        setEventName: (state: InitData, action: PayloadAction<string>) => {
            state.event_name = action.payload
        },
        setThemeColors: (state: InitData, action: PayloadAction<{ colour_1: string, colour_2: string }>) => {
            state.themeColors = action.payload
        },
        setMode: (state: InitData, action: PayloadAction<string>) => {
            state.mode = action.payload
        }
    }
});

export const selectDmId = (state: RootState) => state.initData.dmId;
export const selectRoomId = (state: RootState) => state.initData.roomId;
export const selectDmBack = (state: RootState) => state.initData.dmBack;
export const selectIsAutoAuth = (state: RootState) => state.initData.isAutoAuth;
export const selectEventName = (state: RootState) => state.initData.event_name;
export const selectMode = (state: RootState) => state.initData.mode;

export const { setDmId, setDmBack, setRoomId, setIsAutoAuth, setEventName, setMode } = initDataSlice.actions;

export default initDataSlice.reducer;