import {
	IonBadge,
	IonIcon,
	IonLabel, IonPage, IonRouterOutlet,
	IonTabBar,
	IonTabButton,
	IonTabs
} from "@ionic/react";
import {
	briefcaseOutline,
	chatbubblesOutline,
	fileTrayFullOutline,
	searchOutline,
	starOutline
} from "ionicons/icons";
import { Fragment, useEffect, useState } from "react";
import { Redirect, Route } from "react-router";
import { userNotBlocked, userNotBlockedBy } from "../../app/firebase";
import { useAppSelector } from "../../app/hooks";
import { selectEventToggles } from "../../app/slices/eventSlice";
import {
	selectGroups,
	selectGroupsLastMessages
} from "../../app/slices/groupSlice";
import { selectMatches, selectMatchesCount } from "../../app/slices/matchSlice";
import { selectAllRooms, selectLastMessages } from "../../app/slices/roomSlice";
import {
	selectAllSponsors,
	selectSponsorTitle
} from "../../app/slices/sponsorSlice";
import { selectUser, selectUserId, selectUserSettings, selectUserTimestamps } from "../../app/slices/userSlice";
import { EventToggles } from "../../app/types/eventToggles";
import { InitData } from "../../app/types/InitData";
import {
	updateGroupNotifications, updateRoomsNotifications
} from "../../components/Classes/NotificationControllers";
import { User } from "../Auth/types";
import ChatRooms from "../ChatRooms/ChatRooms";
import Matches from "../Matches/Matches";
import Inbox from "../Messages/Inbox";
import SponsorsList from "../Sponsors/Sponsors";
import UserList from "../Users/UserList";
import "./Home.css";
import { translate } from "../../app/translate";
interface Props {
	viewportInfo: { width: number; height: number };
	initData: InitData;
}

const Home: React.FC<Props> = (props: Props) => {
	const matchCountRaw = useAppSelector(selectMatchesCount);
	const matches = useAppSelector(selectMatches);
	const [matchCount, setMatchCount] = useState(matchCountRaw);
	const userSettings = useAppSelector(selectUserSettings);
	const rooms = useAppSelector(selectAllRooms);
	const groups = useAppSelector(selectGroups);
	const userId = useAppSelector(selectUserId);
	const authUser: User = useAppSelector<User>(selectUser);
	const lastRoomMessages = useAppSelector(selectLastMessages);
	const lastGroupMessages = useAppSelector(selectGroupsLastMessages);
	const userTimestamps = useAppSelector(selectUserTimestamps);
	const [roomUnreadCount, updateRoomUnreadCount] = useState(0);
	const [groupUnreadCount, updateGroupUnreadCount] = useState(0);
	const eventToggles: EventToggles | never[] =
		useAppSelector(selectEventToggles);
	const [homeRoute, setHomeRoute] = useState("/chatrooms");
	const sponsors = useAppSelector(selectAllSponsors);
	const sponsorTitle = useAppSelector(selectSponsorTitle);

	useEffect(() => {
		const checkedCount = matches.filter(match => userNotBlocked(String(match.user.id)) === true).filter(match => userNotBlockedBy(String(match.user.id)) === true).length;
		setMatchCount(checkedCount);
	},[matches, userSettings]);

	useEffect(() => {
		const count = updateRoomsNotifications(
			rooms,
			lastRoomMessages,
			userTimestamps
		);
		updateRoomUnreadCount(count);
	}, [rooms, lastRoomMessages, userTimestamps]);

	useEffect(() => {
		const count = updateGroupNotifications(
			groups,
			lastGroupMessages,
			userTimestamps,
			userId
		);
		updateGroupUnreadCount(count);
	}, [groups, lastGroupMessages, userTimestamps, userId]);

	useEffect(() => {
		if (Object.keys(eventToggles).length === 0) {
			return;
		}
		setHomeRoute(h => eventToggles.chat_rooms ? h : eventToggles.direct_messaging ? "/inbox" : eventToggles.matchmaking ? "/matches" : "/users");
	}, [eventToggles]);

	return (
		<Fragment>
			<IonPage id="home">
				<IonTabs>
					<IonRouterOutlet>
						<Route path={`/home/:tab(chatrooms)`} exact={true}>
							<ChatRooms viewportInfo={props.viewportInfo} />
						</Route>
						<Route path={`/home/:tab(matches)`} exact={true}>
							<Matches />
						</Route>
						<Route path={`/home/:tab(inbox)`} exact={true}>
							<Inbox viewportInfo={props.viewportInfo} />
						</Route>
						<Route path={`/home/:tab(${sponsorTitle})`} exact={true}>
							<SponsorsList />
						</Route>
						<Route path={`/home/:tab(users)`} exact={true}>
							<UserList />
						</Route>
						<Redirect exact path={`/home`} to={`/home${homeRoute}`} />
					</IonRouterOutlet>

					<IonTabBar
						slot="bottom"
						style={{ backgroundColor: `var(--ion-color-primary-contrast)` }}
					>
						{eventToggles.chat_rooms && (
							<IonTabButton
								tab="chatrooms"
								href={`/home/chatrooms`}
								style={{ backgroundColor: `var(--ion-color-primary-contrast)` }}
							>
								<IonIcon icon={chatbubblesOutline} />
								<IonLabel>{translate('Chat Rooms')}</IonLabel>
								{roomUnreadCount > 0 && (
									<IonBadge>
										{roomUnreadCount < 99 ? roomUnreadCount : `99+`}
									</IonBadge>
								)}
							</IonTabButton>
						)}

						{eventToggles.matchmaking && authUser
							// && !authUser.is_sponsor_user
							&& (
							<IonTabButton
								tab="matches"
								href={`/home/matches`}
								style={{ backgroundColor: `var(--ion-color-primary-contrast)` }}
							>
								<IonIcon icon={starOutline} />
								<IonLabel>{translate('Matches')}</IonLabel>
								{matchCount > 0 && (
									<IonBadge>{matchCount < 99 ? matchCount : `99+`}</IonBadge>
								)}
							</IonTabButton>
						)}

						{eventToggles.direct_messaging && (
							<IonTabButton
								tab="inbox"
								href={`/home/inbox`}
								style={{ backgroundColor: `var(--ion-color-primary-contrast)` }}
							>
								<IonIcon icon={fileTrayFullOutline} />
								<IonLabel>{translate('Messages')}</IonLabel>
								{groupUnreadCount > 0 && (
									<IonBadge>
										{groupUnreadCount < 99 ? groupUnreadCount : `99+`}
									</IonBadge>
								)}
							</IonTabButton>
						)}

						{sponsors && sponsors.length > 0 && (
							<IonTabButton
								tab={sponsorTitle}
								href={`/home/${sponsorTitle}`}
								style={{ backgroundColor: `var(--ion-color-primary-contrast)` }}
							>
								<IonIcon icon={briefcaseOutline} />
								<IonLabel>{sponsorTitle}</IonLabel>
								{groupUnreadCount > 0 && (
									<IonBadge>
										{groupUnreadCount < 99 ? groupUnreadCount : `99+`}
									</IonBadge>
								)}
							</IonTabButton>
						)}

						<IonTabButton
							tab="users"
							href={`/home/users`}
							style={{ backgroundColor: `var(--ion-color-primary-contrast)` }}
						>
							<IonIcon icon={searchOutline} />
							<IonLabel>{translate('Search')}</IonLabel>
						</IonTabButton>
					</IonTabBar>
				</IonTabs>
			</IonPage>
		</Fragment>
	);
};

export default Home;
