import {
    IonAvatar, IonButton,
    IonContent,
    IonIcon, IonInput, IonItem,
    IonLabel, IonText,
    useIonAlert, IonPage
} from "@ionic/react";
import { lockOpenOutline } from "ionicons/icons";
import { Fragment, useState } from "react";
import { useUpdatePasswordMutation } from "../../app/services/userApi";
import TabHeader from "../../components/TabHeader";
import './ResetPassword.css';
import { translate } from "../../app/translate";


const ResetPassword: React.FC = props => {
    const [ state, setState ] = useState({
        password: '',
        password_confirmation: '',
    });

    const [ errors, setErrors ] = useState({
        password: '',
        password_confirmation: '',
    });

    const [present] = useIonAlert();

    const [ updatePassword, { isLoading } ] = useUpdatePasswordMutation();

    const handleChange = (e: CustomEvent<object>) => {
        const { name, value } = e.target as HTMLIonInputElement;

        setState((prev) => ({ ...prev, [name] : value }))
        handleValidationError(name, '')
    };

    const handleValidationError = (name: string, value: string) => {
        setErrors((prev) => ({ ...prev, [name] : value }));
    }

    const handleSubmit = async () => {
        let header: string = '';
        let message: string = '';

        try {
            const result = await updatePassword(state);
            if (result.data) {
                header = translate('Updated');
                message = translate('Successfully updated your profile');
            } else {
                switch (result.error.status) {
                    case 422:
                        header = translate('Invalid Data');
                        message = translate('Please fix errors, and resubmit');
                        Object.keys(result.error.data.errors).forEach((key) => {
                            handleValidationError(key, result.error.data.errors[key]);
                        })
                        break;
                    default:
                        header = translate('Error');
                        message = translate('A error occured, please try again later');
                }
            }
        } catch (error) {
            header = translate('Error');
            message = translate('A error occured, please try again later');
        }

        present({
            header: header,
            message: message,
            buttons: [
                'OK'
            ],
        });
    }

    return (
        <IonPage>
            <TabHeader title={translate('Reset Password')} back hideUser />
            <IonContent fullscreen>
                <div className='ion-padding ion-margin-top ion-text-center ion-align-items-center' style={{
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: '420px',
                    margin: 'auto'
                }}>
                    <IonAvatar className='ion-margin-top title-icon'>
                        <IonIcon icon={lockOpenOutline} size="large" color='white' />
                    </IonAvatar>
                    <br/>
                </div>

                <IonItem>
                    <IonLabel position='floating'>{translate('Password')}</IonLabel>
                    <IonInput 
                        type='password' 
                        placeholder={translate('Enter a password')}
                        value={state.password} 
                        name="password"
                        onIonChange={handleChange}
                    />
                </IonItem>
                {
                    errors.password.length > 0 &&
                    <IonText color='danger' className="ion-margin-start">{ errors.password }</IonText>
                }

                <IonItem>
                    <IonLabel position='floating'>{translate('Confirm Password')}</IonLabel>
                    <IonInput 
                        type='password' 
                        placeholder={translate('Enter a password')}
                        value={state.password_confirmation} 
                        name="password_confirmation"
                        onIonChange={handleChange}
                    />
                </IonItem>
                {
                    errors.password_confirmation.length > 0 &&
                    <IonText color='danger' className="ion-margin-start">{ errors.password_confirmation }</IonText>
                }

                <IonButton
                    expand='block'
                    color='primary'
                    className='ion-margin'
                    onClick={handleSubmit}
                >
                    {translate('Send Reset Password Link')}
                </IonButton>
                <br/>
            </IonContent>
        </IonPage>
    )
}

export default ResetPassword;