import {
    IonButton, IonCard,
    IonCardContent, IonContent, IonHeader, IonItem,
    IonLabel, IonPage, IonSelect,
    IonSelectOption,
    useIonAlert
} from "@ionic/react";
import { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useAppSelector } from "../../app/hooks";
import { useUpdateMatchMakingMutation } from "../../app/services/userApi";
import { selectQuestions } from "../../app/slices/questionSlice";
import { selectUserAnswers } from "../../app/slices/userSlice";
import { Answer, Question } from "../../app/types/questions";
import { UserAnswer } from "../../app/types/user";
import GroupedQuestion from "../../components/GroupedQuestion";
import TabHeader from "../../components/TabHeader";
import './MatchMakingSettings.css';
import { translate } from "../../app/translate";


const MatchMakingSettings: React.FC = props => {
    const questions: Array<Question> = useAppSelector(selectQuestions);
    const [updateUserAnswers, { isLoading }] = useUpdateMatchMakingMutation();
    const initAnswers = useAppSelector(selectUserAnswers);
    const [userUpdatedAnswer, setUserUpdatedAnswer] = useState<Array<UserAnswer>>([]);
    const [present] = useIonAlert();
    const history = useHistory();

    useEffect(() => {
        initAnswers && setUserUpdatedAnswer(initAnswers);
    }, [initAnswers])

    const handleChange = (e: CustomEvent<object>) => {
        const target = e.target as HTMLIonInputElement;
        const id = parseInt(target.name);
        const answers = Array.isArray(target.value) ? target.value : [parseInt(String(target.value))];
        
        if (!userUpdatedAnswer) { return };
        var temp = [...userUpdatedAnswer];
        if (temp.filter(item => item.id === id).length <= 0) {
            temp.push({
                id: id,
                answer: Array.isArray(answers) ? answers : [parseInt(String(answers))]
            })
            setUserUpdatedAnswer(temp);
        } else {
            var result = temp.map(el => el.id === id ?
                {
                    ...el,
                    answer: answers
                } : el
            );
            setUserUpdatedAnswer(result);
        }
    }

    const handleGroupChange = (data: { id: number, answers: Array<number> }) => {
        if (!userUpdatedAnswer) { return };
        var temp = [...userUpdatedAnswer];
        if (temp.filter(item => item.id === data.id).length <= 0) {
            temp.push({
                id: data.id,
                answer: data.answers
            })
            setUserUpdatedAnswer(temp);
        } else {
            var result = temp.map(el => el.id === data.id ?
                {
                    ...el,
                    answer: data.answers
                } : el
            );
            setUserUpdatedAnswer(result);
        }
    }

    const handleSubmit = async () => {
        let header: string = '';
        let message: string = '';

        try {
            const result = await updateUserAnswers(userUpdatedAnswer);
            if (result.data) {
                header = translate('Updated');
                message = translate('Successfully updated your profile');
            } else {
                switch (result.error.status) {
                    case 422:
                        header = translate('Invalid Data');
                        message = translate('Please fix errors, and resubmit');
                        break;
                    default:
                        header = translate('Error');
                        message = translate('A error occured, please try again later');
                }
            }
        } catch (error) {
            header = translate('Error');
            message = translate('A error occured, please try again later');
        }

        present({
            header: header,
            message: message,
            buttons: [
                {
                    text: translate('OK'),
                    handler: () => history.push('/home')
                }
            ],
        });
    };

    return (
        <IonPage>
            <TabHeader title={translate('Matchmaking Settings')} back hideUser />
            <IonContent fullscreen>
                <IonHeader>
                    <div className='ion-padding'>
                        {/* <h3 className='ion-no-margin ion-margin-bottom' ><b>Match Making</b></h3> */}
                        <p
                            className='ion-no-margin'
                            style={{
                                padding: '12px',
                                backgroundColor: 'var(--ion-color-light)',
                                borderRadius: '8px',
                                fontSize:'14px'
                            }}
                        >
                            {translate('Answer all the questions below to meet your matches.')} 
                        </p>
                    </div>
                </IonHeader>
                
                {/* <p className='ion-margin'>All Questions Required.</p> */}

                {
                    (questions.length > 0 && userUpdatedAnswer) && questions.map((item, index) => (
                        <IonCard key={String(`question_${item.id}`)}>
                            <IonCardContent>
                                <IonLabel><b>{ `${item.question}` }</b></IonLabel>
                                <IonItem lines="none" className="ion-no-padding">
                                    <IonLabel className='ion-hide'>{`${item.question}`}</IonLabel>
                                    {
                                        (item.type !== 'group') ?
                                            <IonSelect
                                                placeholder={item.type !== 'multiple' ? 'Select One' : 'Select Many'}
                                                className='ion-no-padding ion-padding-end matchmaking-select'
                                                multiple={item.type === 'multiple'}
                                                interface="action-sheet"
                                                name={String(item.id)}
                                                onIonChange={handleChange}
                                                value={
                                                    userUpdatedAnswer.filter(question => question.id === item.id)[0] ?
                                                    userUpdatedAnswer.filter(question => question.id === item.id)[0].answer
                                                        : []
                                                }
                                            >
                                                {
                                                    item.answers.map((answer: Answer, index) => (
                                                        <IonSelectOption
                                                            key={`question_${item.id}-answer_${answer.id}`}
                                                            value={answer.id}
                                                        >
                                                            {answer.answer}
                                                        </IonSelectOption>
                                                    ))
                                                }
                                            </IonSelect>
                                            :
                                            <GroupedQuestion
                                                question={item}
                                                userInitAnswers={
                                                    userUpdatedAnswer.filter(question => question.id === item.id)[0] ?
                                                    userUpdatedAnswer.filter(question => question.id === item.id)[0].answer : []
                                                }
                                                parentHandleChange={(data:{ id:number, answers:Array<number> }) => handleGroupChange(data)}
                                            />
                                    }
                                </IonItem>
                            </IonCardContent>
                        </IonCard>
                    ))
                }

                <IonButton
                    expand='block'
                    color='primary'
                    className='ion-margin'
                    onClick={handleSubmit}
                >
                    {translate('Save')}
                </IonButton>
                <br/>
            </IonContent>
        </IonPage>
    )
}

export default MatchMakingSettings;