import { Fragment, useCallback, useState } from "react"
import { IonItem, useIonActionSheet, IonLabel, useIonAlert, IonLoading, IonChip } from "@ionic/react";
import { ActionSheetButton } from '@ionic/core';
import { useHistory } from 'react-router';
import UserAvatar from "./UserAvatar";
import { useAppSelector } from "../app/hooks";
import { getDMGroup2, userNotBlocked } from "../app/firebase";
import { useUserBlockMutation, useUserUnBlockMutation } from "../app/services/userApi";
import { selectEventToggles } from '../app/slices/eventSlice';
import { EventToggles } from '../app/types/eventToggles';
import UserInfoChecked from '../components/Classes/UserInfoChecked'
import { selectGroupByRecipient } from "../app/slices/groupSlice";
import { User } from "../pages/Auth/types";
import { selectSponsorTitle } from "../app/slices/sponsorSlice";
import { translate } from "../app/translate";

interface Props {
    id: number;
    index?: number;
    firstName: string;
    lastName: string;
    jobTitle?: string | null;
    organisation?: string | null;
    status?: string;
    profileImage?: string | null;
    children?: any;
    actionObj?: any;
    is_sponsor_user?: boolean;
    showActions?: boolean;
    user: User;
}

const UserItem: React.FC<Props> = (props: Props) => {
    const [present, dismiss] = useIonActionSheet();
    const history = useHistory();
    const showActions = props.hasOwnProperty('showActions') ? props.showActions : true;
    const [showDMLoader, setshowDMLoader] = useState(false);
    const [block, { isBlockLoading }] = useUserBlockMutation();
    const [unblock, { isUnBlockLoading }] = useUserUnBlockMutation();
    const eventToggles: EventToggles | never[] = useAppSelector(selectEventToggles);
    const dmGroup = useAppSelector(selectGroupByRecipient(props.id));
    const [alert] = useIonAlert();
    const sponsorTitle = useAppSelector(selectSponsorTitle);

    const createAlertMessageBtn = useCallback(() => {
        let buttons: Array<ActionSheetButton> = [];
        if (userNotBlocked(String(props.id))) {
            buttons = [
                {
                    text: translate('View Profile'),
                    handler: () => history.push({
                        pathname: `/userprofile/${props.id}`,
                        state: {
                            id: props.id,
                            firstName: props.firstName,
                            lastName: props.lastName,
                            jobTitle: props.jobTitle,
                            organisation: props.organisation,
                            profilePicture: props.profileImage,
                            status: props.status,
                            is_sponsor_user: props.is_sponsor_user
                        }
                    })
                },
                // { text: `Video Call ${props.firstName}` }
            ];
            eventToggles.direct_messaging && buttons.unshift({
                text: `Message ${props.firstName}`,
                handler: async () => {
                    setshowDMLoader(true);
                    const targetGroupId = await getDMGroup2(props.user);
                    setshowDMLoader(false);
                    history.push(`/group/${targetGroupId}`);
                }
            });
            buttons.push({
                text: `Block ${props.firstName}`,
                handler: async () => {
                    const response: { data: { success: boolean } } = await block({
                        target: props.id,
                    })

                    var message = translate(`Failed to block the :firstName`, {firstName: props.firstName});
                    var header = translate("Error");
                    if (response.data.success) {
                        message = translate(`Successfully blocked :firstName`, {firstName: props.firstName});
                        header = translate("Success");
                    }

                    alert({
                        header,
                        message,
                        buttons: [
                            'OK',
                        ],
                    });
                }
            })
        } else {
            buttons.push({
                text: `Unblock ${props.firstName}`,
                handler: async () => {
                    const response: { data: { success: boolean } } = await unblock({
                        target: props.id,
                    })

                    var message = translate(`Failed to unblock :firstName`, {firstName: props.firstName});
                    var header = translate("Error");
                    if (response.data.success) {
                        message = translate(`Successfully unblocked :firstName`, {firstName: props.firstName});
                        header = translate("Success");
                    }

                    alert({
                        header,
                        message,
                        buttons: [
                            'OK',
                        ],
                    });
                }
            })
        }

        buttons.push({
            text: translate('Cancel'),
            role: 'cancel'
        })

        return buttons;
    }, [eventToggles, props, history, block, unblock, alert, dmGroup]);

    return (
        <Fragment>
            <IonItem
                key={props.index ? props.index : props.id}
                style={{ cursor: 'pointer' }}
                onClick={!showActions ? () => { } :
                    props.actionObj ? () => present(props.actionObj) :
                        () => present({ buttons: createAlertMessageBtn() })
                }
                className='ion-margin-bottom'
            >
                <UserAvatar
                    id={props.id}
                    firstName={props.firstName}
                    img={props.profileImage ? props.profileImage : null}
                    status={props.status}
                />
                <IonLabel>
                    <h2>{`${props.firstName} ${props.lastName}`}</h2>
                    <p><UserInfoChecked jobTitle={props.jobTitle} organisation={props.organisation} /></p>
                </IonLabel>
                {
                    props.is_sponsor_user || props.user.is_sponsor_user &&
                    <IonChip>{sponsorTitle}</IonChip>
                }
            </IonItem>
            <IonLoading
                isOpen={showDMLoader}
                message={translate('Loading Chat...')}
                onDidDismiss={() => setshowDMLoader(false)}
            />
        </Fragment>
    )
}

export default UserItem;