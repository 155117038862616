import { Timestamp } from "@firebase/firestore";
import { IonButton, IonIcon, IonInput, IonToolbar, IonAlert } from "@ionic/react";
// import Picker from 'emoji-picker-react';
import 'emoji-mart/css/emoji-mart.css'
import { EmojiData, Picker } from 'emoji-mart'
import { happyOutline } from "ionicons/icons";
import { Fragment, memo, useCallback, useRef, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import { selectUser } from "../../app/slices/userSlice";
import { MessagePayload } from '../../models/message';
import { userNotBlocked } from '../../app/firebase';
import './MessageInput.css';
import { translate } from "../../app/translate";

interface Props {
    recipient: number | string;
    addNewMessage: (newMessage: MessagePayload) => void;
    lastMessage: number;
    disabled?: boolean;
    type: string;
    dmId?: string | number;
}

var EmojiConvertor = require('emoji-js');

const MessageInput: React.FC<Props> = (props: Props) => {
    const [message, setMessage] = useState('');
    const [emojiPickerOpen, setEmojiPickerOpen] = useState(false);
    const messageInputRef = useRef<HTMLIonInputElement>(null);
    const [showBlockAlert, setShowBlockAlert] = useState(false);
    var emojiConverter = new EmojiConvertor();
    emojiConverter.replace_mode = 'unified';
    emojiConverter.allow_native = true;
    const user = useAppSelector(selectUser);

    const sendMessage = useCallback(
        () => {
            // check if the target user is blocked first
            if (props.type === 'dm') {
                if (!userNotBlocked(String(props.dmId))) {
                    setShowBlockAlert(true);
                    return
                }
            }

            const newMessage: MessagePayload = {
                id: null,
                message: message,
                sender: user,
                recipient: props.recipient,
                timestamp: Timestamp.now(),
                systemMessage: false,
                status: 'sent'
            };
            props.addNewMessage(newMessage);
            setMessage('');
            setEmojiPickerOpen(false);

        }, [message, props, user]
    );

    const keySend = useCallback(
        (e: any) => {
            if (e.key === 'Enter' && message.trim().length > 0) {
                sendMessage();
            }
        },
        [message, sendMessage],
    )

    const addEmoji = async (emoji: EmojiData) => {
        const element = await messageInputRef.current?.getInputElement();
        let temp: string = '';
        if (element?.selectionStart && element?.selectionEnd) {
            temp = `${message.slice(0, element.selectionStart)} ${emoji.colons} ${message.slice(element.selectionEnd)}`;
        } else {
            temp = ` ${emoji.colons} `;
        }
        setMessage(temp);
    };

    return (
        <Fragment>
            <IonToolbar>
            <IonAlert
                isOpen={showBlockAlert}
                onDidDismiss={() => setShowBlockAlert(false)}
                header={translate('Cannot Send Message')}
                message={translate('You have blocked this user. Unblock the user first.')}
                buttons={['OK']}
            />
                <IonInput
                    type='text'
                    ref={messageInputRef}
                    value={emojiConverter.replace_colons(message)}
                    placeholder={translate('Type your message...')}
                    className=''
                    onIonChange={(e) => setMessage(e.detail.value!)}
                    onKeyPress={(e) => keySend(e)}
                    disabled={props.disabled}
                ></IonInput>
                {
                    window.innerWidth >= 480 &&
                    <Fragment>
                        <IonButton slot='end' color='warning' onClick={() => setEmojiPickerOpen(!emojiPickerOpen)}>
                            <IonIcon icon={happyOutline} />
                        </IonButton>
                    </Fragment>
                }
                <IonButton
                    slot='end'
                    onClick={() => sendMessage()}
                    disabled={message.trim().length <= 0}
                >Send</IonButton>
            </IonToolbar>
            {
                window.innerWidth >= 480 &&
                <div className={`message-input-emoji-picker${emojiPickerOpen ? ' open':''}`}>
                    <Picker
                        set='apple'
                        native
                        onSelect={(emoji: EmojiData) => addEmoji(emoji)}
                        style={{
                            width: '100%'
                        }}
                    />
                </div>
            }
        </Fragment>
    );
};

export default memo(MessageInput);