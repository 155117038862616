import {
    IonButton,
    IonContent, IonItem,
    IonLabel, IonList, IonSelect,
    IonSelectOption, IonSkeletonText, IonSpinner, IonToggle,
    useIonAlert, IonPage
} from "@ionic/react";
import { Fragment, useEffect, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import { useUpdateUserSettingsMutation } from "../../app/services/userApi";
import { selectUserSettings } from "../../app/slices/userSlice";
import TabHeader from "../../components/TabHeader";
import { UserSettingsPayload } from "../Auth/types";
import { translate } from "../../app/translate";

const NotificationsSettings: React.FC = props => {
    const initSettings = useAppSelector(selectUserSettings);
    const [settings, setSettings] = useState(initSettings);
    const [ updateUserSettings, { isLoading } ] = useUpdateUserSettingsMutation();

    const [ present ] = useIonAlert();

    const handleChange = (e: CustomEvent<object>) => {
        const { name, value } = e.target as HTMLIonInputElement;

        let val: any = null;
        if (value === 'on') {
            const { checked } = e.detail as { checked: boolean };
            val =  (checked === true);
        } else {
            val = value;
        }

        setSettings((prev) => ({ ...prev, [name] : val }))
    };

    const handleSubmit = async () => {
        let header: string = '';
        let message: string = '';

        // submits to laravel
        const payload: UserSettingsPayload = {
            flg_alert_mail: settings.missedChatEmail,
            flg_mail_freq: settings.missedChatFrequency,
            flg_alert_push: settings.pushNotifications,
            status: settings.status,
        }

        const result = await updateUserSettings(payload);

        try {
            if (result.data) {
                header = translate('Updated');
                message = translate('Successfully updated your settings');
            } else {
                header = translate('Error');
                message = translate('A error occured, please try again later');
            }
        } catch (error) {
            header = translate('Error');
            message = translate('A error occured, please try again later');
        }

        present({
            header: header,
            message: message,
            buttons: [
                'OK'
            ],
        });
    }

    useEffect(() => {
        initSettings.isInit && setSettings(initSettings);
    }, [initSettings])

    return (
        <IonPage>
            <TabHeader title={translate('Notifications')} back hideUser />
            <IonContent fullscreen>
                <IonList>
                    {!initSettings.isInit ?
                        <Fragment>
                            <IonItem>
                                <IonLabel><IonSkeletonText style={{width: '50%'}} animated /></IonLabel>
                                <IonToggle disabled></IonToggle>
                            </IonItem>
                            
                            <IonItem>
                                <IonLabel><IonSkeletonText style={{width: '50%'}} animated /></IonLabel>
                                <IonSelect disabled>
                                    <IonSelectOption><IonSkeletonText animated /></IonSelectOption>
                                </IonSelect>
                            </IonItem>
                            <IonItem>
                                <IonLabel><IonSkeletonText style={{width: '50%'}} animated /></IonLabel>
                                <IonToggle disabled></IonToggle>
                            </IonItem>
                        </Fragment>
                        :
                        <Fragment>
                            <IonItem>
                                <IonLabel>{translate('Missed Chat Email')}</IonLabel>
                                <IonToggle 
                                    checked={settings.missedChatEmail}
                                    onIonChange={handleChange}
                                    name="missedChatEmail"
                                ></IonToggle>
                            </IonItem>
                            <IonItem>
                                <IonLabel>{translate('Frequency')}</IonLabel>
                                <IonSelect
                                    value={settings.missedChatFrequency}
                                    placeholder={translate("Select One")}
                                    onIonChange={handleChange}
                                    name="missedChatFrequency"
                                >
                                    <IonSelectOption value={0}>{translate('5 Minutes')}</IonSelectOption>
                                    <IonSelectOption value={1}>{translate('Daily')}</IonSelectOption>
                                    <IonSelectOption value={2}>{translate('Weekly')}</IonSelectOption>
                                </IonSelect>
                            </IonItem>
                            <IonItem>
                                <IonLabel>{translate('Push Notifications')}</IonLabel>
                                <IonToggle 
                                    checked={settings.pushNotifications}
                                    onIonChange={handleChange}
                                    name="pushNotifications"
                                ></IonToggle>
                            </IonItem>
                        </Fragment>
                    }   
                </IonList>

                {
                    initSettings.isInit &&
                    <IonButton
                        expand='block'
                        color='primary'
                        className='ion-margin'
                        onClick={handleSubmit}
                        disabled={isLoading}
                    >
                    {isLoading ?
                        <IonSpinner />
                        : translate('Save Changes')
                    }
                    </IonButton>
                }
                <br/>
            </IonContent>
        </IonPage>
    )
}

export default NotificationsSettings;