import {
    IonBadge,
    IonButton, IonContent, IonIcon, IonItem,
    IonLabel, IonList, IonNote, IonPage
} from "@ionic/react";
import { chatbubbleEllipsesOutline, searchCircleOutline, chatbubble } from 'ionicons/icons';
import { Fragment, useEffect, useState, useCallback } from 'react';
import Moment from 'react-moment';
import { shallowEqual } from "react-redux";
import { useHistory } from "react-router";
import { useAppSelector } from "../../app/hooks";
import { selectGroupCounts, selectGroups, selectGroupsIsInit, selectGroupsLastMessages } from "../../app/slices/groupSlice";
import { removeCurrentChannel, selectUserId, selectUserTimestamps } from "../../app/slices/userSlice";
import { store } from "../../app/store";
import { Group } from "../../app/types/group";
import EmptyContent from '../../components/EmptyContent';
import MessageListSkeleton from "../../components/Skeleton/MessageListSkeleton";
import TabHeader from "../../components/TabHeader";
import UserAvatar from "../../components/UserAvatar";
import DirectMessageNonIonic from "./DirectMessageNonIonic";
import { userNotBlocked, userNotBlockedBy } from '../../app/firebase';
import { translate } from "../../app/translate";

interface Props{
    viewportInfo: { width: number, height: number };
}

const Inbox: React.FC<Props> = (props: Props) => {
    const history = useHistory();
    const initGroups = useAppSelector(selectGroups);
    const [groups, setGroups] = useState(initGroups);
    const userId = useAppSelector(selectUserId);
    const userTimestamps = useAppSelector(selectUserTimestamps);
    const groupsIsInit = useAppSelector(selectGroupsIsInit);
    const allGroupsCount = useAppSelector(selectGroupCounts);
    const lastMessages = useAppSelector(selectGroupsLastMessages, shallowEqual);

    const [openedChats, setOpenedChats] = useState<Array<string>>([]);
    const openExistingChats = (groupId: string) => {
        if (openedChats.includes(groupId)) {
            return
        }
        let temp: Array<string> = [];
        if (openedChats.length <= 0) {
            temp.push(groupId);
        } else {
            temp = [...openedChats];
            temp.push(groupId)
        }
        setOpenedChats(temp);
    }
    const closeExistingChats = (groupId: string) => {
        store.dispatch(removeCurrentChannel(groupId));
        setOpenedChats(openedChats.filter((chat) => chat !== groupId));
    }

    useEffect(() => {
        if (!initGroups) { return };
        const tempGroups = [...initGroups];
        tempGroups.sort((a, b) => {
            if (!a.messages || a.messages.length <= 0 || !b.messages || b.messages.length <= 0) { return 0 };

            if (a.messages[a.messages.length - 1].timestamp > b.messages[b.messages.length - 1].timestamp) {
                return -1
            }
            if (a.messages[a.messages.length - 1].timestamp < b.messages[b.messages.length - 1].timestamp) {
                return 1
            }
            return 0
        });
        setGroups(tempGroups);
    }, [initGroups, allGroupsCount, groupsIsInit]);

    const GetThumbnail = (item: Group): string => {
        if (item.directMessage === true) {
            const target = item.members.filter((member: number) => member != userId)[0];
            if (target in item.users) {
                return item.users[target].profilePicture;
            }
            return "";
        } else {
            return item.meta.thumbnail;
        }
    }

    const GetStatus = (item: Group): string => {
        if (item.directMessage === true) {
            const target = item.members.filter((member: number) => member != userId)[0];
            if (target in item.users) {
                return translate(item.users[target].online ? "online" : "offline");
            }
            return translate("offline");
        } else {
            return translate("disabled");
        }

    }

    const GetName = (item: Group): string => {

        if (item.directMessage === true) {
            const target = item.members.filter((member: number) => member != userId)[0];
            if (target in item.users) {
                return item.users[target].firstName;
            }
            return "";
        } else {
            return item.name;
        }
    }

    const GetNotBlockedByCheck = useCallback(
        (item: Group): boolean => {

            if (item.directMessage === true) {
                const target = item.members.filter((member: number) => member != userId)[0];
                if (target) {
                    return userNotBlockedBy(String(target));
                }
                return true;
            } else {
                return true;
            }
        },
        [userId]
    )

    const CheckHasMessages = useCallback(
        (group: Group): boolean => {
            if (group.messages && group.messages.length > 0) {
                return true;
            } else {
                return false
            }
        },
        [],
    )

    useEffect(() => {
        if (!groups || groups.length <= 0) { return };
        const checkedBlockedDMs = groups.filter(group => GetNotBlockedByCheck(group));
        const checkedHasMessagesGroups = checkedBlockedDMs.filter(group => CheckHasMessages(group));
        if (checkedHasMessagesGroups.length !== groups.length) { setGroups(checkedHasMessagesGroups) };
    }, [groups, GetNotBlockedByCheck, CheckHasMessages]);

    const EmptyContentComponent =
        <EmptyContent
            header={translate(`Let's get chatting`)}
            message={translate(`You currently have no messages.`)}
            icon={chatbubbleEllipsesOutline}
        >
            <br />
            <IonButton
                color='secondary'
                onClick={() => history.push('/home/users')}
            >
                <IonIcon icon={searchCircleOutline} className='ion-margin-end' />
                {translate('Connect with attendees')}
            </IonButton>
        </EmptyContent>
        
    return (
        <IonPage>
            {
                props.viewportInfo.width > 960 ?
                    <Fragment>
                        <TabHeader title={translate('Your Private Messages')} />
                        <IonContent>
                            <div className='chats-desktop-container'>
                                <div className='chats-desktop-sidebar'>
                                    {!groupsIsInit ? <MessageListSkeleton count={6} /> : Object.values(groups).length <= 0 ?
                                        EmptyContentComponent
                                        :
                                        <IonList lines="inset">
                                            {Object.values(groups).map((item) => (
                                                <IonItem
                                                    key={item.id}
                                                    style={{ cursor: openedChats.includes(item.id) ? 'default' : 'pointer' }}
                                                    onClick={() => openExistingChats(item.id)}
                                                    color={openedChats.includes(item.id) ? 'light' : ''}
                                                >
                                                    <UserAvatar
                                                        id={parseInt(item.id)}
                                                        firstName={GetName(item)}
                                                        img={GetThumbnail(item)}
                                                        status={GetStatus(item)}
                                                    />
                                                    <IonLabel>
                                                        <h2>{GetName(item)}</h2>
                                                        {lastMessages[item.id] &&
                                                            <p
                                                                style={{
                                                                    color: 'var(--ion-color-medium)',
                                                                    display: 'flex',
                                                                    justifyContent: 'start',
                                                                    alignItems: 'center'
                                                                }}    
                                                            >
                                                                <IonIcon icon={chatbubble} style={{ marginRight: '8px' }} />
                                                                {lastMessages[item.id].message}
                                                            </p>
                                                        }
                                                        {
                                                            lastMessages[item.id] &&
                                                            <p
                                                                style={{
                                                                    fontSize: '8px',
                                                                    color: 'var(--ion-color-medium)'
                                                                }}
                                                            ><Moment fromNow>{lastMessages[item.id] ? lastMessages[item.id].time * 1000 : ''}</Moment></p>
                                                        }
                                                        
                                                        {openedChats.includes(item.id) ?
                                                            <Fragment><IonBadge color='warning' className='ion-margin-top'>Opened</IonBadge></Fragment> : <br />
                                                        }
                                                    </IonLabel>
                                                    {
                                                        ((lastMessages[item.id] && userTimestamps[item.id] && lastMessages[item.id].time > parseInt(userTimestamps[item.id])) || (lastMessages[item.id] && !userTimestamps[item.id])) &&
                                                            <IonBadge slot='end'>new</IonBadge>
                                                    }
                                                    
                                                </IonItem>
                                            ))}
                                        </IonList>
                                    }
                                </div>
                                <div className='chats-desktop-content'>
                                    {
                                        openedChats.map((groupId, index) => (
                                            <Fragment key={String(groupId)}>
                                                <div
                                                    className='chats-desktop-window'
                                                    key={String(`chats-desktop-window_${groupId}`)}
                                                    style={{
                                                        width: `${100 / (openedChats.length <= 4 ? openedChats.length : 4)}%`,
                                                        height: `${100 / Math.ceil(openedChats.length/4)}%`
                                                    }}
                                                >
                                                    <DirectMessageNonIonic
                                                        groupId={groupId}
                                                        isWindow
                                                        onClose={() => closeExistingChats(groupId)}
                                                    />
                                                </div>
                                            </Fragment>
                                        ))
                                    }
                                </div>
                            </div>
                        </IonContent>
                    </Fragment>
                    :
                    <Fragment>
                        <TabHeader title={translate('Your Private Messages')} />
                        <IonContent
                            // fullscreen
                        >
                            { !groupsIsInit ? <MessageListSkeleton count={6} /> : Object.values(groups).length <= 0 ?
                                EmptyContentComponent
                                :
                                <IonList lines="inset">
                                    {Object.values(groups).map((item) => (
                                        <IonItem
                                            key={item.id}
                                            id={String(item.id)}
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => history.push(`${process.env.PUBLIC_URL}/group/${item.id}`)}
                                        >
                                            <UserAvatar
                                                id={parseInt(item.id)}
                                                firstName={GetName(item)}
                                                img={GetThumbnail(item)}
                                                status={GetStatus(item)}
                                            />
                                            <IonLabel>
                                                <h2>{GetName(item)}</h2>
                                                {lastMessages[item.id] &&
                                                    <p
                                                        style={{
                                                            color: 'var(--ion-color-medium)',
                                                            display: 'flex',
                                                            justifyContent: 'start',
                                                            alignItems: 'center'
                                                        }}    
                                                    >
                                                        <IonIcon icon={chatbubble} style={{ marginRight: '8px' }} />
                                                        {lastMessages[item.id].message}
                                                    </p>
                                                }
                                            </IonLabel>
                                            {
                                                ((lastMessages[item.id] && userTimestamps[item.id] && lastMessages[item.id].time > parseInt(userTimestamps[item.id])) || (lastMessages[item.id] && !userTimestamps[item.id])) &&
                                                    <IonBadge slot='end'>new</IonBadge>
                                            }
                                            {
                                                lastMessages[item.id] &&
                                                <IonNote slot="end" color='dark'>
                                                    <span
                                                        style={{
                                                            fontSize: '10px'
                                                        }}
                                                    ><Moment fromNow>{lastMessages[item.id] ? lastMessages[item.id].time * 1000 : ''}</Moment></span>
                                                </IonNote>
                                            }
                                        </IonItem>
                                    ))}
                                </IonList>
                            }
                        </IonContent>
                        {/* <IonFooter>
                            <IonToolbar>
                                <IonTitle>Footer</IonTitle>
                            </IonToolbar>
                        </IonFooter> */}
                    </Fragment>
            }
        </IonPage>
    )
}

export default Inbox;